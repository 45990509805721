<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Login -->
      <b-card class="mb-0">
        <image-logo />

        <b-card-title class="mb-1 text-center">
          Validação de conta
        </b-card-title>
        <b-card-text class="mb-2 text-center">
          Enviamos um código de validação para o endereço de e-mail:
          <strong>{{ email }}</strong>
        </b-card-text>

        <validation-observer
          ref="loginForm"
          #default="{invalid}"
        >
          <b-form
            class="auth-login-form mt-2 aligh-center"
            @submit.prevent
          >
            <b-card-text class="text-center">
              Digite o código abaixo:
            </b-card-text>

            <!-- code -->
            <validation-provider
              ref="code"
              #default="{ errors }"
              name="código"
              rules="required"
            >
              <b-form-row
                label-for="name"
                class="justify-content-center"
              >
                <b-form-input
                  id="code_0"
                  v-model="registerCode[0]"
                  name="code_0"
                  class="code text-primary"
                  maxlength="1"
                  :number="true"
                  autofocus
                  @blur="validCode"
                  @keyup="focusNext($event)"
                />
                <b-form-input
                  id="code_1"
                  v-model="registerCode[1]"
                  name="code_1"
                  class="code text-primary"
                  maxlength="1"
                  :number="true"
                  @blur="validCode"
                  @keyup="focusNext($event)"
                />
                <b-form-input
                  id="code_2"
                  v-model="registerCode[2]"
                  name="code_2"
                  class="code text-primary"
                  maxlength="1"
                  :number="true"
                  @blur="validCode"
                  @keyup="focusNext($event)"
                />
                <b-form-input
                  id="code_3"
                  v-model="registerCode[3]"
                  name="code_3"
                  class="code text-primary"
                  maxlength="1"
                  :number="true"
                  @blur="validCode"
                  @keyup="focusNext($event)"
                />
                <b-form-input
                  id="code_4"
                  v-model="registerCode[4]"
                  name="code_4"
                  class="code text-primary"
                  maxlength="1"
                  :number="true"
                  @blur="validCode"
                  @keyup="focusNext($event)"
                />
                <b-form-input
                  id="code_5"
                  v-model="registerCode[5]"
                  name="code_5"
                  class="code text-primary"
                  maxlength="1"
                  :number="true"
                  @keyup="validCode"
                />
                <small class="text-danger">{{ errors[0] }}</small>

                <small class="text-muted d-block px-2 text-center mt-1 font-italic">Seu código pode levar alguns minutos para chegar. Verifique sua caixa de spam.</small>
              </b-form-row>
            </validation-provider>
          </b-form>

          <alert-message :status="status" />

          <!-- submit button -->
          <b-button
            variant="primary"
            type="submit"
            block
            class="mt-2"
            :disabled="invalid"
            @click="validationForm"
          >
            CONTINUAR
          </b-button>
          <div class="mt-2 text-center">
            <b-link
              :to="{name:'register-email'}"
            >
              <u>VOLTAR</u>
            </b-link>
          </div>

          <small class="mt-2 d-block px-2 text-center">Não recebeu o código? Aguarde <span class="d-inline-block label-seconds">{{ time | prettify }}s</span> e clique abaixo para enviar um novo código</small>

          <div class="mt-1 mb-3 text-center">
            <b-link
              :class="{'text-dark': disableResend}"
              :disabled="disableResend"
              @click="sendEmail"
            >
              <u>REENVIAR CÓDIGO</u>
            </b-link>
          </div>

        </validation-observer>
        <b-progress
          value="28.58"
          max="100"
          class="progress-bar-primary mt-2"
          variant="primary"
        />
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import {
  BButton, BForm, BFormInput, BFormRow, BCard, BLink, BCardTitle, BCardText, BProgress,
} from 'bootstrap-vue'
import { required } from '@validations'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AlertMessage from '@/layouts/components/AlertMessage.vue'
import ImageLogo from '@/layouts/components/ImageLogo.vue'

export default {
  components: {
    // BSV
    BButton,
    BForm,
    BFormInput,
    BFormRow,
    BCard,
    BCardTitle,
    BLink,
    BCardText,
    BProgress,
    AlertMessage,
    ImageLogo,
    ValidationProvider,
    ValidationObserver,

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },

  filters: {
    prettify(value) {
      const time = value / 60
      let seconds = Math.round((time) * 60)

      if (seconds < 10) {
        seconds = `0${seconds}`
      }

      return seconds
    },
  },

  data() {
    return {
      registerCode: [],
      email: store.state.register.email,
      minutes: 0,
      seconds: 0,
      time: 60,
      timer: null,
      status: {},
      disableResend: true,
      // validation rules
      required,
    }
  },

  mounted() {
    if (this.email === '') {
      this.$store.commit('register/UPDATE_EMAIL', this.email)
    }
    this.countDown()
    localize(this.$store.getters['cuido/locale'])
  },
  methods: {
    validationForm() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          const code = this.registerCode.join('').toString()
          this.$store.dispatch('register/validateCode', { email: this.email, code })
            .then(() => {
              this.$store.commit('register/UPDATE_CODE', this.registerCode)
              this.$router.push('/register-name')
            })
            .catch(error => {
              const object = Object.is(error)
              if (object === true) {
                this.status = {
                  color: 'danger',
                  error: true,
                  message: 'Erro interno. Tente novamente.',
                }
              } else if (error.data.error.code === 200007) {
                this.status = {
                  color: 'danger',
                  error: true,
                  message: 'Código não correspondente ao e-mail enviado',
                }
              }
            })
        }
      })
    },
    sendEmail() {
      const userEmail = this.email
      this.$store.dispatch('register/sendEmail', { email: userEmail })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Pronto!',
              text: 'Enviamos um novo código de acesso para o e-mail informado.',
              icon: 'CheckIcon',
              variant: 'success',
              solid: true,
            },
          },
          {
            position: 'top-center',
            timeout: 5000,
          })
        })
        .catch(error => {
          const object = Object.is(error)
          if (object === true) {
            this.status = {
              error: true,
              color: 'danger',
              message: 'Erro interno. Tente novamente.',
            }
          } else if (error.data.error.code === 200006) {
            this.status = {
              error: true,
              color: 'danger',
              message: 'E-mail já cadastrado',
            }
          }
        })
      this.time = 60
      this.timer = null
      this.disableResend = true
      this.countDown()
    },

    focusNext(e) {
      const inputs = Array.from(e.target.form.querySelectorAll('input[type="text"]'))
      const index = inputs.indexOf(e.target)

      if (e.key === '0' || e.key === '1' || e.key === '2' || e.key === '3' || e.key === '4' || e.key === '5' || e.key === '6' || e.key === '7' || e.key === '8' || e.key === '9') {
        if (index < inputs.length) {
          inputs[index + 1].focus()
        }
      }
    },

    validCode() {
      const code = this.registerCode.filter(Number.isFinite)
      if (code.length !== 6) {
        this.$refs.code.setErrors(['O campo código inválido'])
      } else {
        this.$refs.code.setErrors('')
      }
    },

    countDown() {
      if (!this.timer) {
        this.timer = setInterval(() => {
          if (this.time > 0) {
            this.time -= 1
          } else {
            clearInterval(this.timer)
            this.disableResend = false
          }
        }, 1000)
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>

<style>
  .code{
    width: 45px !important;
    height: 50px;
    margin-left: 5px;
  }
  .disabled {
    opacity: 0.4;
  }
</style>

<style scoped>
  .card-body {
    padding-bottom: 1px;
  }
  input {
    font-size: 24px;
    font-weight: bold;
    color: #2196F3;
    text-align: center;
  }
  .label-seconds {
    min-width: 22px;
  }
</style>
